<template>
  <div class="page-content contact">
    <h1 class="page-title">
      {{ $t("contact.title") }}
    </h1>
    <div class="desc" v-html="$t('contact.desc')"></div>
    <div class="steps">
      <div
        class="step"
        :class="{ 'is-success': active > idx, active: idx == active }"
        v-for="(step, idx) in $t('contact.steps').split(',')"
        :key="step"
      >
        <span> {{ idx + 1 }}.{{ step }}</span>
      </div>
    </div>
    <div class="line-divider"></div>

    <h3 v-if="active < 2" class="title">
      {{ $t("contact.formTitle") }}
    </h3>
    <template v-else>
      <h3 class="title">
        {{ $t("contact.successTitle") }}
      </h3>
      <div class="desc" v-html="$t('contact.successDesc')"></div>
    </template>

    <!-- -->
    <el-form
      v-show="active < 2"
      ref="form"
      :model="form"
      :rules="rules"
      :disabled="active > 0"
    >
      <template v-for="(item, key, idx) in formProps">
        <el-form-item
          :key="idx + ','"
          :class="{
            last: key == 'privacyPolicy',
            contentVerify: key == 'content',
          }"
          :prop="key"
          :label="$t(item.label)"
        >
          <template slot="label">
            {{
              key == "name"
                ? $t("contact.surname") + $t("contact.name")
                : $t(item.label)
            }}<span class="required">{{ $t("required") }}</span></template
          >
          <p v-if="key == 'content'">{{ $t("contact.contentVerify") }}</p>
          <div class="name-input" v-if="key == 'name'">
            <!-- 姓名分开 -->
            <el-input
              :placeholder="$t('contact.surname')"
              v-model="form.surname"
            ></el-input>
            <el-input
              :placeholder="$t(item.placeholder)"
              v-model="form.name"
            ></el-input>
          </div>
          <el-input
            :placeholder="$t(item.placeholder)"
            v-else-if="item.type == 'input'"
            v-model="form[key]"
          ></el-input>
          <el-input
            :placeholder="$t(item.placeholder)"
            v-else-if="item.type == 'textarea'"
            type="textarea"
            :readonly="item.readonly"
            v-model="form[key]"
            rows="12"
          ></el-input>
          <el-select
            :placeholder="$t(item.placeholder)"
            :filterable="$t(item.filterable)"
            v-if="item.type == 'select'"
            v-model="form[key]"
          >
            <el-option
              v-for="(opt, idx) in item.optins"
              :key="idx + '.'"
              :label="$i18n.locale == 'zh' ? opt.value : opt.name"
              :value="opt.value"
            ></el-option>
          </el-select>
          <span class="verify" v-if="item.verify">*{{ $t(item.verify) }}</span>

          <!-- 隐私政策 -->
          <template v-if="key == 'privacyPolicy'">
            <p>{{ $t("contact.privacyPolicyDesc1") }}</p>
            <div>
              <el-checkbox v-model="form.agree">{{
                $t("contact.agree")
              }}</el-checkbox>
            </div>
          </template>
        </el-form-item>
        <!-- 分割线 -->
        <div v-if="item.isBordered" :key="idx + '1'" class="dashed-line"></div>
        <el-form-item v-if="idx == 2" :key="idx + '_1'">
          <el-row class="companyDesc" gutter="80">
            <el-col class="left" :span="9" :xs="24">
              <el-image
                :src="path + data.logo3"
                @click="$router.push('/')"
              ></el-image>
              <p>
                {{ $t("addr") }}:{{
                  $i18n.locale == "zh" ? data.address : data.address_en
                }}
              </p>
              <p>{{ $t("tel") }}:{{ data.phone }}</p>
              <p>{{ $t("email") }}:{{ data.email }}</p>
            </el-col>
            <el-col class="right" :span="15" :xs="24">
              <el-image
                :src="require('@/assets/img/map.png')"
                :preview-src-list="[require('@/assets/img/map.png')]"
              ></el-image>
            </el-col>
          </el-row>
        </el-form-item>
        <div  v-if="idx == 2" :key="idx + '_1'" class="dashed-line"></div>
      </template>
    </el-form>
    <div class="flex justify-center">
      <el-button
        v-if="active == 0"
        :disabled="!form.agree"
        type="primary"
        round
        @click="submit"
        >{{ $t("contact.submit") }}<i class="el-icon-arrow-right"></i
      ></el-button>
      <template v-else-if="active == 1">
        <el-button round plain @click="active = 0">{{
          $t("contact.edit")
        }}</el-button>
        <el-button type="primary" round @click="submit">{{
          $t("contact.send")
        }}</el-button>
      </template>
      <template v-else>
        <el-button round plain @click="$router.push('/')"
          >{{ $t("contact.return") }}<i class="el-icon-arrow-right"></i
        ></el-button>
      </template>
    </div>
  </div>
</template>

<script>
import { country } from "@/i18n/country.js";
import { zh } from "@/i18n/zh.js";
import { en } from "@/i18n/en.js";
export default {
  data() {
    const _this = this;
    const requiredFun = (rule, value, callback) => {
      if (!value) {
        let msg = _this.$t(rule.message1) + _this.$t("contact." + rule.field);
        if (rule.field == "name" || rule.field == "surname") {
          msg =
            _this.$t(rule.message1) +
            _this.$t("contact.surname") +
            _this.$t("contact.name");
        }
        return callback(new Error(msg));
      }
      callback();
    };
    const nameVerify = (rule, value, callback) => {
      // console.log(this.form.name, this.form.surname)
      if (this.form.name && this.form.surname) {
        let name = this.form.name + this.form.surname;
        if (name.length < 2 || name.length > 10) {
          let msg = _this.$t(rule.message1);
          return callback(new Error(msg));
        }
      }
      callback();
    };
    const companyVerify = (rule, value, callback) => {
      if (value && value.length > 30) {
        let msg = _this.$t(rule.message1);
        return callback(new Error(msg));
      }
      callback();
    };

    return {
      path: config.BASEURL,
      data: {},
      active: 0,
      rules: {
        companyname: [
          {
            required: true,
            message1: "contact.input",
            trigger: "blur",
            validator: requiredFun,
          },
          {
            min: 3,
            max: 30,
            message1: "contact.companyVerify",
            trigger: "blur",
            validator: companyVerify,
          },
        ],
        name: [
          {
            required: true,
            message1: "contact.input",
            trigger: "blur",
            validator: requiredFun,
          },
          {
            min: 3,
            max: 10,
            message1: "contact.nameVerify",
            trigger: "blur",
            validator: nameVerify,
          },
        ],
        // country: [
        //   {
        //     required: true,
        //     message1: "contact.select",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        phone: [
          {
            required: true,
            message1: "contact.input",
            trigger: "blur",
            validator: requiredFun,
          },
        ],
        // address1: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        // city: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        // state: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        // zip: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],

        // email: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        // type: [
        //   {
        //     required: true,
        //     message1: "contact.select",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
        // content: [
        //   {
        //     required: true,
        //     message1: "contact.input",
        //     trigger: "blur",
        //     validator: requiredFun,
        //   },
        // ],
      },
      form: {
        privacyPolicy: _this.$t("contact.privacyPolicyDesc"),
      },
      formProps: {
        companyname: {
          label: "contact.companyname",
          type: "input",
          placeholder: "contact.companyname",
          verify: "contact.companyVerify",
        },
        name: {
          label: "contact.name",
          type: "input",
          placeholder: "contact.name",
          verify: "contact.nameVerify",
        },

        phone: {
          label: "contact.phone",
          type: "input",
          placeholder: "contact.phone",
          isBordered: true,
        },
        // country: {
        //   label: "contact.country",
        //   type: "select",
        //   placeholder: "contact.countryPlaceholder",
        //   optins: country,
        //   filterable: true,
        // },
        // address1: {
        //   label: "contact.address1",
        //   type: "input",
        //   placeholder: "contact.address1",
        // },
        // address2: {
        //   label: "contact.address2",
        //   type: "input",
        //   placeholder: "contact.address2",
        // },
        // city: {
        //   label: "contact.city",
        //   type: "input",
        //   placeholder: "contact.city",
        // },
        // state: {
        //   label: "contact.state",
        //   type: "input",
        //   placeholder: "contact.state",
        // },
        // zip: {
        //   label: "contact.zip",
        //   type: "input",
        //   placeholder: "contact.zip",
        //   isBordered: true,
        // },
        email: {
          label: "contact.email",
          type: "input",
          placeholder: "contact.email",
        },
        // notes: {
        //   label: "contact.notes",
        //   type: "input",
        //   placeholder: "contact.notes",
        //   isBordered: true,
        // },
        type: {
          label: "contact.type",
          type: "select",
          placeholder: "contact.typePlaceholder",
          optins: [
            {
              name: en.product1.subTitle,
              value: zh.product1.subTitle,
            },
            {
              name: en.product2.subTitle,
              value: zh.product2.subTitle,
            },
            {
              name: en.product3.subTitle,
              value: zh.product3.subTitle,
            },
            {
              name: "Other Products",
              value: "其他产品",
            },
          ],
        },
        content: {
          label: "contact.content",
          type: "textarea",
          placeholder: "contact.contentPlaceholder",
          isBordered: true,
        },
        privacyPolicy: {
          label: "contact.privacyPolicy",
          type: "textarea",
          readonly: true,
        },
      },
    };
  },
  watch: {
    "$i18n.locale"() {
      this.form.privacyPolicy = this.$t("contact.privacyPolicyDesc");
    },
  },
  created() {
    this.$api.getCompany().then((res) => {
      this.data = res.data;
    });
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid && this.active == 0) {
          this.active = 1;
          this.$refs.form.clearValidate();
        } else if (valid && this.active == 1) {
          // 提交信息
          let data = { ...this.form };
          if ("privacyPolicy" in data) delete data.privacyPolicy;
          data.agree = data.agree ? "1" : "0";
          this.$api.saveUser(data).then((res) => {
            console.log(res);
            this.active = 2;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact {
  .desc {
    font-size: calc(var(--font-size) - 4px);
  }
  .el-button {
    border-radius: 100px;
    padding: 19px 69px 22px 69px;
    ::v-deep span,
    i {
      font-weight: 300;
      font-size: calc(var(--font-size) - 6px);
    }
    i {
      margin-left: 17px;
    }
    & + .el-button {
      margin-left: 40px;
    }
  }
  .steps {
    margin-top: calc(78px / 2);
    margin-bottom: 40px;
  }
  .title {
    font-weight: 400;
    font-size: calc(var(--font-size) + 6px);
    margin-top: 25px;
    margin-bottom: 40px;
    color: $--color-primary;
    & + .desc {
      margin-bottom: 40px;
    }
  }
  .el-form {
    .dashed-line + .el-form-item {
      padding-top: 40px;
    }
    .el-form-item {
      margin-bottom: 40px;
      &.contentVerify {
        position: relative;
        p {
          position: absolute;
          top: -50px;
          left: 0;
          color: #1a1a1a;
          z-index: 10;
        }
      }
      &.last {
        ::v-deep .el-form-item__content {
          flex-wrap: wrap;
        }
        p {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 20px;
          font-weight: 300;
          font-size: calc(var(--font-size) - 8px);
          color: #1a1a1a;
          line-height: 30px;
        }
        .el-checkbox {
          display: flex;
          align-items: center;
          ::v-deep .el-checkbox__inner {
            background: #fff;
          }
          ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
            &::after {
              left: 50%;
              margin-left: -5px;
              width: 10px;
              height: 18px;
              border-color: $--color-primary;
              border-width: 0 2px 2px 0;
            }
          }
          ::v-deep .el-checkbox__label {
            margin-left: 20px;
          }
        }
      }
      &.is-required {
        .required {
          display: block;
        }
      }
      ::v-deep .el-form-item__error {
        bottom: -30px;
        top: unset;
        font-size: calc(var(--font-size) - 6px);
      }
      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        padding-left: 20px;
        padding-right: 40px;
      }
      ::v-deep .el-textarea__inner {
        padding-top: 30px;
      }

      ::v-deep .el-form-item__content {
        display: flex;
      }
      .el-select {
        ::v-deep .el-input__suffix {
          right: 30px;
        }
        ::v-deep .el-input .el-select__caret {
          font-size: var(--font-size);
        }
      }
      .verify {
        margin-left: 40px;
        color: $--color-primary;
        white-space: nowrap;
      }
      .name-input {
        display: flex;
        width: 100%;
        .el-input + .el-input {
          margin-left: 40px;
        }
      }
    }
    ::v-deep .el-form-item__label {
      font-size: calc(var(--font-size) - 4px);
      display: flex;
      width: 300px;
      &::before {
        display: none;
        align-items: center;
      }
      .required {
        color: $--color-primary;
        margin-left: 24px;
        display: none;
      }
    }
  }
}
</style>
